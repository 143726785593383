.profit-amount{
    font-size: 3rem;
    font-weight: 700;
}

.profit-amount.gain{
    background: linear-gradient(0deg, rgba(255,255,255,1) 30%, rgba(82,255,0,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.profit-amount.loss{
    background: linear-gradient(0deg, rgba(255,255,255,1) 30%, rgba(221,110,7,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
