// index.scss

@import '../../../styles/variables.scss';
// Custom styles for the header
.packageCard{
  padding: 20px;
  border-radius: 15px;
  border: 1px solid rgba(29, 30, 37, 0.15);
  margin-bottom: 20px;
  .cardcontainer{
    display: flex;
    justify-content: space-between;
    .intertnal{
      display: flex;
      gap: 1rem;
      .imagesSection{
        width: 100px;
        height: 100px;
        border-radius: 12px;
        background: rgba(222, 12, 68, 0.10);
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          object-fit: contain;
  
        }
      }
      .pkgdetail{
  
        .packageInfo{
          display: flex;
          gap: 15px;
          margin-bottom: 14px;
          h1{
            color: #1D1E25;
            font-feature-settings: 'clig' off, 'liga' off;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
          .badge{
            padding: 5px 15px;
            border-radius: 30px;
            background: rgba(222, 12, 68, 0.10);
            color: $primary-color;
            font-feature-settings: 'clig' off, 'liga' off;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
        .packageEssentials{
          display: flex;
          gap: 50px;
          .info{
            .heading{
              color: $text-color-paragraph;
              font-feature-settings: 'clig' off, 'liga' off;
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 25px
            }
            .detail{
              color:$text-color-heading;
              font-feature-settings: 'clig' off, 'liga' off;
              font-family: Inter;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 25px;
            }
          }
        }
      }
    }
    .linkArrow{
      height: auto;
      border-radius: 0px 10px 10px 0px;
      background: rgba(222, 12, 68, 0.10);
      display: flex;
      align-items: center;
      justify-content: 'center';
      max-width: 44px;
      min-width: 44px;
      svg{
        margin-inline:auto ;
      }
  
    }
  
  }
  &.active-package{
  border-radius: 15px;
  background: linear-gradient(91deg, #DD0744 0.7%, #FF9A44 143.6%);
  .cardcontainer{
    display: flex;
    justify-content: space-between;
    .intertnal{
      display: flex;
      gap: 1rem;
      .imagesSection{
        width: 100px;
        height: 100px;
        border-radius: 12px;
        background: rgba(222, 12, 68, 0.10);
        display: flex;
        align-items: center;
        justify-content: center;
       
        img{
          object-fit: contain;
          filter: grayscale(100%) brightness(1000%);

        }
      }
      .pkgdetail{

        .packageInfo{
          display: flex;
          gap: 15px;
          margin-bottom: 14px;
          h1{
            color: #fff;
            font-feature-settings: 'clig' off, 'liga' off;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
          .badge{
            padding: 5px 15px;
            border-radius: 30px;
            background:#DE0C44;
            color: $primary-color;
            font-feature-settings: 'clig' off, 'liga' off;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            color: #fff;
          }
          .ant-dropdown-trigger{
            svg{
              path{
                fill: #fff;
              }
            }
          }
        }
        .packageEssentials{
          display: flex;
          gap: 50px;
          .info{
            .heading{
              color: #fff;
              font-feature-settings: 'clig' off, 'liga' off;
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 25px;
              opacity: 0.7;

            }
            .detail{
              color: #fff;
              font-feature-settings: 'clig' off, 'liga' off;
              font-family: Inter;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 25px;
            }
          }
        }
      }
    }
    .linkArrow{
      height: auto;
      border-radius: 0px 10px 10px 0px;
      border-radius: 0px 10px 10px 0px;
      background: rgba(0, 0, 0, 0.10);
      // background: rgba(222, 12, 68, 0.10);
      display: flex;
      align-items: center;
      justify-content: 'center';
      max-width: 44px;
      min-width: 44px;
      svg{
        margin-inline:auto ;
        path{
          stroke: #fff;
        }
      }

    }

  }
  }

}