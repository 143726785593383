@import '../../../styles/variables.scss';


.Welcome-banner{
    background: url('../../../../public/images/countdownbg.png') center/cover no-repeat; /* Replace 'path-to-your-image.jpg' */
    border-radius: 16px;
    display: flex;
    flex-direction: column;
   
    align-items: center;
    padding: 20px;
    
    .internal-content{
        padding-block: 40px;
    padding-inline: 50px;
    min-height: 200px;
        margin: auto;
        text-align: center;
        h1{
            color: #fff;
            font-feature-settings: 'clig' off, 'liga' off;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            margin-bottom: 15px;
            line-height: normal;
            span{
                color: $primary-color;
            }
        }
        p{
            color: #fff;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        .reset-heading{
            display: flex;
            align-items: center;
            justify-content: center;
            color: #FFF;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Inter;
            font-size: 25px;
            font-style: normal;
            font-weight: 600;
            line-height: 33px; /* 132% */
            margin-bottom: 20px;
            .box{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 44px;
                height: 44px;
                border-radius: 15px;
                background: linear-gradient(91deg, #DD0744 0.7%, #FF9A44 143.6%);
                margin-right: 15px;
            }

        }
        .reset-note{

            margin-top: 10px;
            margin-bottom: 30px;
            font-weight: 600;
        }
        button{
            min-width: 320px;
        }
    }
    .trade-detail-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        h1{
            color: #FFF;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Inter;
            font-size: 25px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
        p{
            color: #FFF;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px; /* 156.25% */
            text-transform: capitalize;
            opacity: 0.7;
        }
        button{
            display: flex;
            align-items: center;
            span{
                margin-right: 10px;
            }
        }
    }
}