// index.scss

@import '../../../styles/variables.scss';
// Custom styles for the header
.stat-card{
  border-radius: 10px;
  background: #F6F6F6;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 13px;
  .heading{
    color: #717184;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
  }
  .value{
    color: #1D1E25;
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 156.25% */
  }
}