// index.scss

@import '../../../styles/variables.scss';
// Custom styles for the header

// Countdown.scss
.countdownCardCompetition{
  border-radius: 16px;
  display: flex;
  flex-direction: column;
 
  .countdown-container {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 14px;
    margin-bottom: 29px;
  
    .countdown-box {
      display: block;
      width: 49px;
      height: 54px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: #FFF;
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      border-radius: 10px;
      background: #F6F6F6;
      .value{
        color: #1D1E25;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .label{
        color: #717184;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px; /* 154.545% */

      }
    }
  }
  .gmt{
    color: #FFF;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    margin-block: 1rem;

  } 
 
}



@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes flip {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(180deg);
  }
}
