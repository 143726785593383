.reset-challenge-page .ant-select-selection-placeholder{
    z-index: 1;
}

.reset-challenge-page .ant-checkbox-wrapper .ant-checkbox{
    align-self: flex-start;
    margin-top: .25rem;
}

.reset-challenge-page .checkbox-card {
    background: #F6F6F6;
}

/* .reset-challenge-page .box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 15px;
    background: linear-gradient(91deg, #DD0744 0.7%, #FF9A44 143.6%);
    margin-right: 15px;
} */

.reset-challenge-page .checkbox-card.active {
    background-image: linear-gradient(to right, #DD0744 0%, #FF9A44 130%);
}

.reset-challenge-page .step{
    background-color: #fff;
}

.reset-challenge-page .step .step-order{
    background-image: linear-gradient(to right, #DD0744 0%, #FF9A44 130%);
    border-radius: 1rem;
    color: #fff;
    font-size: 1.563rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.125rem;
    width: 3.125rem;
}

.reset-challenge-page .step .step-title{
    color: #000;
    font-size: 1rem;
    font-weight: 500;
}

.reset-challenge-page .step.active{
    background-color: transparent;
    background-image: linear-gradient(to right, #DD0744 0%, #FF9A44 130%);
}

.reset-challenge-page .step.active .step-order{
    color: #DD0744;
    background: #fff;
}

.reset-challenge-page .step.active .step-title{
    color: #fff;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner, :where(.css-dev-only-do-not-override-1k979oh).ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner{
    border-color: #DD0744 !important;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-checkbox-checked .ant-checkbox-inner{
    border-color: #DD0744 !important;
}

.reset-challenge-page .ant-checkbox-wrapper.ant-checkbox-wrapper-checked .ant-checkbox .ant-checkbox-inner{
    background-color: #DD0744 !important;
}

.reset-challenge-page .custom-primary-button {
  border-radius: 15px;
  background: linear-gradient(91deg, #DD0744 0.7%, #FF9A44 143.6%);
  color: #fff;
}

.reset-challenge-page .ant-radio-wrapper:hover :where(.css-dev-only-do-not-override-1okl62o).ant-radio-wrapper, 
.reset-challenge-page :where(.css-dev-only-do-not-override-1okl62o).ant-radio-wrapper:hover .ant-radio-inner{
    border-color: #DD0744 !important;
}

.reset-challenge-page :where(.css-dev-only-do-not-override-1okl62o).ant-radio-wrapper .ant-radio-checked .ant-radio-inner{
    border-color: #DD0744;
    background-color: #DD0744;
}

.reset-challenge-page :where(.css-dev-only-do-not-override-1okl62o).ant-radio-wrapper .ant-radio-inner{
    height: 22px;
    width: 22px;
}

.reset-challenge-page :where(.css-dev-only-do-not-override-1okl62o).ant-radio-wrapper .ant-radio-inner::after{
    height: 22px;
    width: 22px;
    margin-block-start: -11px;
    margin-inline-start: -11px;
}