// index.scss

@import '../../../styles/variables.scss';
// Custom styles for the header
.video-card{
    background: #fff;
    padding:20px ;
    border-radius: 15px;
    margin-bottom:30px ;
    .video-item{
        position: relative;
        cursor: pointer;
        img{
            max-width: 100%;
            margin: auto auto 17px auto;
        }
        svg{
            position:absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
        }
    }
    .video-title{
        color: #1D1E25;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px; /* 162.5% */
        text-align: center;
    }
   
}