@import '../../../styles/variables.scss';


.copyableinput{
   display: flex;
   border-radius: 12px;
  border: 1px solid #E9E9E9;
  background: #F6F6F6;
  min-height: 45px;
   input{
     background-color: #F6F6F6!important;
     border: none;
     border-radius: 12px;
     min-height: 43px;


   }
   button{
    background-color: #F6F6F6!important;
    border: none;
    border-radius: 12px;
    min-height: 43px;

   }
}
.portal{
  border: none !important;
  background: transparent !important;
  input{
    margin-right: 10px;
  }
 .custom-primary-button{
  span{
    margin-inline: 5px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
 }
}