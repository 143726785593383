.purchase-challenge-page .ant-select-selection-placeholder{
    z-index: 1;
}

.purchase-challenge-page .ant-checkbox-wrapper .ant-checkbox{
    align-self: flex-start;
    margin-top: .25rem;
}

.purchase-challenge-page .checkbox-card{
    background: #F6F6F6;
}

.purchase-challenge-page .checkbox-card.active{
    background-image: linear-gradient(to right, #DD0744 0%, #FF9A44 130%);
}

.purchase-challenge-page .step{
    background-color: #fff;
}

.purchase-challenge-page .step .step-order{
    background-image: linear-gradient(to right, #DD0744 0%, #FF9A44 130%);
    border-radius: 1rem;
    color: #fff;
    font-size: 1.563rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.125rem;
    width: 3.125rem;
}

.purchase-challenge-page .step .step-title{
    color: #000;
    font-size: 1rem;
    font-weight: 500;
}

.purchase-challenge-page .step.active{
    background-color: transparent;
    background-image: linear-gradient(to right, #DD0744 0%, #FF9A44 130%);
}

.purchase-challenge-page .step.active .step-order{
    color: #DD0744;
    background: #fff;
}

.purchase-challenge-page .step.active .step-title{
    color: #fff;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner, :where(.css-dev-only-do-not-override-1k979oh).ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner{
    border-color: #DD0744 !important;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-checkbox-checked .ant-checkbox-inner{
    border-color: #DD0744 !important;
}

.purchase-challenge-page .ant-checkbox-wrapper.ant-checkbox-wrapper-checked .ant-checkbox .ant-checkbox-inner{
    background-color: #DD0744 !important;
}

/************** Purchase Challenge Radio Button **************/
.purchase-challenge-page .ant-radio-wrapper:hover :where(.css-dev-only-do-not-override-1okl62o).ant-radio-wrapper, 
.purchase-challenge-page :where(.css-dev-only-do-not-override-1okl62o).ant-radio-wrapper:hover .ant-radio-inner{
    border-color: #DD0744 !important;
}

.purchase-challenge-page :where(.css-dev-only-do-not-override-1okl62o).ant-radio-wrapper .ant-radio-checked .ant-radio-inner{
    border-color: #DD0744;
    background-color: #DD0744;
}

.purchase-challenge-page :where(.css-dev-only-do-not-override-1okl62o).ant-radio-wrapper .ant-radio-inner{
    height: 22px;
    width: 22px;
}

.purchase-challenge-page :where(.css-dev-only-do-not-override-1okl62o).ant-radio-wrapper .ant-radio-inner::after{
    height: 22px;
    width: 22px;
    margin-block-start: -11px;
    margin-inline-start: -11px;
}

/* .purchase-challenge-page .checkbox-card .ant-form-item{
    margin-bottom: 0;
} */

/* .ant-radio-wrapper .ant-radio-inner{
    width: 20px;
    height: 20px;
    border-radius: 6px;
}

.ant-radio-wrapper .ant-radio-inner::after{
    inset-block-start: unset;
    margin-block-start: unset;
    margin-inline-start: unset;
    background-color: unset;
    border-block-start: unset;
    border-radius: unset;
}

.ant-radio-wrapper:hover .ant-radio-inner{
    border-color: #DD0744;
}

.ant-radio-wrapper .ant-radio-checked::after{
    border: unset;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner{
    border-color: #fff;
    background-color: #fff;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after{
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    inset-inline-start: 30%;
    display: table;
    width: 5.7142857142857135px;
    height: 9.142857142857142px;
    border: 2px solid #DD0744;
    border-top: 0;
    border-inline-start: 0;
    transform: rotate(45deg) scale(1) translate(-50%,-50%);
    opacity: 1;
    content: "";
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    
} */