// index.scss

@import '../../../styles/variables.scss';
// Custom styles for the header
.user-card{
  border-radius: 15px;
  margin-bottom: 20px;
  .cardcontainer{
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    flex-direction: column;
    border-radius: 16px;
    padding: 20px;
    .intertnal{
      display: flex;
      gap: 1rem;
      margin-bottom: 20px;
      .imagesSection{
        width: 100px;
        height: 100px;
        border-radius: 12px;
        background: rgba(222, 12, 68, 0.10);
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          object-fit: contain;
  
        }
      }
      .packageInfo{
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-bottom: 14px;
        h1{
          color: #1D1E25;
          font-feature-settings: 'clig' off, 'liga' off;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        .balance{
          color: $text-color-paragraph;
          font-size: 14px;
          font-weight: 400;
          line-height: 25px;
          span{
            color: $primary-color;
          }
        }
        .subtitle{
          color: $text-color-paragraph;
        }
      }
    }
      .pkgdetail{
        
        
        .packageEssentials{
          display: flex;
          flex-direction: column;
          .info{
            display: flex;
            gap: 30px;
            margin-bottom: 10px;
            .heading{
              color: $text-color-paragraph;
              font-feature-settings: 'clig' off, 'liga' off;
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 25px;
              min-width: 130px;
            }
            .detail{
              color:$text-color-heading;
              font-feature-settings: 'clig' off, 'liga' off;
              font-family: Inter;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 25px;
            }
          }
        }
      }
  
  }



}