:where(.css-dev-only-do-not-override-1k979oh).ant-input-outlined.ant-input-disabled, 
:where(.css-dev-only-do-not-override-1k979oh).ant-input-outlined[disabled]{
    background-color: rgba(0, 0, 0, 0.1) !important;
    color: rgba(0, 0, 0, 1) !important;
}

.ant-tabs{
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
}

.ant-tabs .ant-tabs-tab{
    padding-bottom: .75rem;
}

.ant-tabs .ant-tabs-content-holder{
    padding-top: 0;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-tabs .ant-tabs-tab:hover{
    color: #DD0744;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-tabs .ant-tabs-tab-btn:focus-visible{
    outline: unset;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner, :where(.css-dev-only-do-not-override-1k979oh).ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner{
    border-color: #DD0744 !important;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-checkbox-checked .ant-checkbox-inner{
    border-color: #DD0744 !important;
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-checked .ant-checkbox .ant-checkbox-inner{
    background-color: #DD0744 !important;
}