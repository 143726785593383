
// @import url('https:/./fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

// /* cyrillic */
// @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 100;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
//   }
//   /* greek-ext */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 100;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+1F00-1FFF;
//   }
//   /* greek */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 100;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
//   }
//   /* vietnamese */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 100;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
//   }
//   /* latin-ext */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 100;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
//   }
  /* latin */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
//   /* cyrillic-ext */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 200;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
//   }
//   /* cyrillic */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 200;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
//   }
//   /* greek-ext */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 200;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+1F00-1FFF;
//   }
//   /* greek */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 200;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
//   }
//   /* vietnamese */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 200;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
//   }
//   /* latin-ext */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 200;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
//   }
  /* latin */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
//   /* cyrillic-ext */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 300;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
//   }
//   /* cyrillic */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 300;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
//   }
//   /* greek-ext */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 300;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+1F00-1FFF;
//   }
//   /* greek */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 300;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
//   }
//   /* vietnamese */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 300;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
//   }
//   /* latin-ext */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 300;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
//   }
  /* latin */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
//   /* cyrillic-ext */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 400;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
//   }
//   /* cyrillic */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 400;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
//   }
//   /* greek-ext */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 400;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+1F00-1FFF;
//   }
//   /* greek */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 400;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
//   }

/****************************************************************/

  /* vietnamese */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 400;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
//   }
  /* latin-ext */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 400;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
//   }
  /* latin */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
//   /* cyrillic-ext */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 500;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
//   }
//   /* cyrillic */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 500;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
//   }
//   /* greek-ext */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 500;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+1F00-1FFF;
//   }
//   /* greek */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 500;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
//   }
//   /* vietnamese */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 500;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
//   }
//   /* latin-ext */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 500;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
//   }
//   /* latin */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
//   /* cyrillic-ext */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 600;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
//   }
//   /* cyrillic */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 600;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
//   }
//   /* greek-ext */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 600;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+1F00-1FFF;
//   }
//   /* greek */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 600;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
//   }
//   /* vietnamese */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 600;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
//   }
//   /* latin-ext */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 600;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
//   }
//   /* latin */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
//   /* cyrillic-ext */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 700;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
//   }
//   /* cyrillic */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 700;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
//   }
//   /* greek-ext */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 700;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+1F00-1FFF;
//   }
//   /* greek */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 700;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
//   }
//   /* vietnamese */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 700;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
//   }
//   /* latin-ext */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 700;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
//   }
//   /* latin */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
//   /* cyrillic-ext */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 800;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
//   }
//   /* cyrillic */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 800;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
//   }
//   /* greek-ext */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 800;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+1F00-1FFF;
//   }
//   /* greek */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 800;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
//   }
//   /* vietnamese */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 800;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
//   }
//   /* latin-ext */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 800;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
//   }
//   /* latin */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
//   /* cyrillic-ext */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 900;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
//   }
//   /* cyrillic */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 900;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
//   }
//   /* greek-ext */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 900;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+1F00-1FFF;
//   }
//   /* greek */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 900;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
//   }
//   /* vietnamese */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 900;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
//   }
//   /* latin-ext */
//   @font-face {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 900;
//     font-display: swap;
//     src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2) format('woff2');
//     unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
//   }
//   /* latin */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(./fonts/Inter/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

/****************************************************************/

@font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggqxSvfedN62Zw.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic-ext */
// @font-face {
//     font-family: 'Manrope';
//     font-style: normal;
//     font-weight: 200;
//     font-display: swap;
//     src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggqxSvfedN62Zw.woff2) format('woff2');
//     unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
//   }
//   /* cyrillic */
//   @font-face {
//     font-family: 'Manrope';
//     font-style: normal;
//     font-weight: 200;
//     font-display: swap;
//     src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggexSvfedN4.woff2) format('woff2');
//     unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
//   }
//   /* greek */
//   @font-face {
//     font-family: 'Manrope';
//     font-style: normal;
//     font-weight: 200;
//     font-display: swap;
//     src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggSxSvfedN62Zw.woff2) format('woff2');
//     unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
//   }
//   /* vietnamese */
//   @font-face {
//     font-family: 'Manrope';
//     font-style: normal;
//     font-weight: 200;
//     font-display: swap;
//     src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggixSvfedN62Zw.woff2) format('woff2');
//     unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
//   }
//   /* latin-ext */
//   @font-face {
//     font-family: 'Manrope';
//     font-style: normal;
//     font-weight: 200;
//     font-display: swap;
//     src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggmxSvfedN62Zw.woff2) format('woff2');
//     unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
//   }
  /* latin */
  @font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggexSvfedN4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
//   /* cyrillic-ext */
//   @font-face {
//     font-family: 'Manrope';
//     font-style: normal;
//     font-weight: 300;
//     font-display: swap;
//     src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggqxSvfedN62Zw.woff2) format('woff2');
//     unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
//   }
//   /* cyrillic */
//   @font-face {
//     font-family: 'Manrope';
//     font-style: normal;
//     font-weight: 300;
//     font-display: swap;
//     src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggexSvfedN4.woff2) format('woff2');
//     unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
//   }
//   /* greek */
//   @font-face {
//     font-family: 'Manrope';
//     font-style: normal;
//     font-weight: 300;
//     font-display: swap;
//     src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggSxSvfedN62Zw.woff2) format('woff2');
//     unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
//   }
//   /* vietnamese */
//   @font-face {
//     font-family: 'Manrope';
//     font-style: normal;
//     font-weight: 300;
//     font-display: swap;
//     src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggixSvfedN62Zw.woff2) format('woff2');
//     unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
//   }
//   /* latin-ext */
//   @font-face {
//     font-family: 'Manrope';
//     font-style: normal;
//     font-weight: 300;
//     font-display: swap;
//     src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggmxSvfedN62Zw.woff2) format('woff2');
//     unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
//   }
  /* latin */
  @font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggexSvfedN4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
//   /* cyrillic-ext */
//   @font-face {
//     font-family: 'Manrope';
//     font-style: normal;
//     font-weight: 400;
//     font-display: swap;
//     src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggqxSvfedN62Zw.woff2) format('woff2');
//     unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
//   }
//   /* cyrillic */
//   @font-face {
//     font-family: 'Manrope';
//     font-style: normal;
//     font-weight: 400;
//     font-display: swap;
//     src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggOxSvfedN62Zw.woff2) format('woff2');
//     unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
//   }
//   /* greek */
//   @font-face {
//     font-family: 'Manrope';
//     font-style: normal;
//     font-weight: 400;
//     font-display: swap;
//     src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggSxSvfedN62Zw.woff2) format('woff2');
//     unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
//   }
//   /* vietnamese */
//   @font-face {
//     font-family: 'Manrope';
//     font-style: normal;
//     font-weight: 400;
//     font-display: swap;
//     src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggixSvfedN62Zw.woff2) format('woff2');
//     unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
//   }
//   /* latin-ext */
//   @font-face {
//     font-family: 'Manrope';
//     font-style: normal;
//     font-weight: 400;
//     font-display: swap;
//     src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggmxSvfedN62Zw.woff2) format('woff2');
//     unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
//   }
  /* latin */
  @font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggexSvfedN4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
//   /* cyrillic-ext */
//   @font-face {
//     font-family: 'Manrope';
//     font-style: normal;
//     font-weight: 500;
//     font-display: swap;
//     src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggqxSvfedN62Zw.woff2) format('woff2');
//     unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
//   }
//   /* cyrillic */
//   @font-face {
//     font-family: 'Manrope';
//     font-style: normal;
//     font-weight: 500;
//     font-display: swap;
//     src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggexSvfedN4.woff2) format('woff2');
//     unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
//   }
//   /* greek */
//   @font-face {
//     font-family: 'Manrope';
//     font-style: normal;
//     font-weight: 500;
//     font-display: swap;
//     src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggSxSvfedN62Zw.woff2) format('woff2');
//     unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
//   }
//   /* vietnamese */
//   @font-face {
//     font-family: 'Manrope';
//     font-style: normal;
//     font-weight: 500;
//     font-display: swap;
//     src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggixSvfedN62Zw.woff2) format('woff2');
//     unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
//   }
//   /* latin-ext */
//   @font-face {
//     font-family: 'Manrope';
//     font-style: normal;
//     font-weight: 500;
//     font-display: swap;
//     src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggmxSvfedN62Zw.woff2) format('woff2');
//     unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
//   }
  /* latin */
  @font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggexSvfedN4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
//   /* cyrillic-ext */
//   @font-face {
//     font-family: 'Manrope';
//     font-style: normal;
//     font-weight: 600;
//     font-display: swap;
//     src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggqxSvfedN62Zw.woff2) format('woff2');
//     unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
//   }
//   /* cyrillic */
//   @font-face {
//     font-family: 'Manrope';
//     font-style: normal;
//     font-weight: 600;
//     font-display: swap;
//     src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggexSvfedN4.woff2) format('woff2');
//     unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
//   }
//   /* greek */
//   @font-face {
//     font-family: 'Manrope';
//     font-style: normal;
//     font-weight: 600;
//     font-display: swap;
//     src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggSxSvfedN62Zw.woff2) format('woff2');
//     unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
//   }
//   /* vietnamese */
//   @font-face {
//     font-family: 'Manrope';
//     font-style: normal;
//     font-weight: 600;
//     font-display: swap;
//     src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggixSvfedN62Zw.woff2) format('woff2');
//     unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
//   }
//   /* latin-ext */
//   @font-face {
//     font-family: 'Manrope';
//     font-style: normal;
//     font-weight: 600;
//     font-display: swap;
//     src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggmxSvfedN62Zw.woff2) format('woff2');
//     unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
//   }
  /* latin */
  @font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggexSvfedN4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
//   /* cyrillic-ext */
//   @font-face {
//     font-family: 'Manrope';
//     font-style: normal;
//     font-weight: 700;
//     font-display: swap;
//     src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggqxSvfedN62Zw.woff2) format('woff2');
//     unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
//   }
//   /* cyrillic */
//   @font-face {
//     font-family: 'Manrope';
//     font-style: normal;
//     font-weight: 700;
//     font-display: swap;
//     src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggexSvfedN4.woff2) format('woff2');
//     unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
//   }
//   /* greek */
//   @font-face {
//     font-family: 'Manrope';
//     font-style: normal;
//     font-weight: 700;
//     font-display: swap;
//     src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggSxSvfedN62Zw.woff2) format('woff2');
//     unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
//   }
//   /* vietnamese */
//   @font-face {
//     font-family: 'Manrope';
//     font-style: normal;
//     font-weight: 700;
//     font-display: swap;
//     src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggixSvfedN62Zw.woff2) format('woff2');
//     unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
//   }
//   /* latin-ext */
//   @font-face {
//     font-family: 'Manrope';
//     font-style: normal;
//     font-weight: 700;
//     font-display: swap;
//     src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggmxSvfedN62Zw.woff2) format('woff2');
//     unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
//   }
  /* latin */
  @font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggexSvfedN4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
//   /* cyrillic-ext */
//   @font-face {
//     font-family: 'Manrope';
//     font-style: normal;
//     font-weight: 800;
//     font-display: swap;
//     src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggqxSvfedN62Zw.woff2) format('woff2');
//     unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
//   }
//   /* cyrillic */
//   @font-face {
//     font-family: 'Manrope';
//     font-style: normal;
//     font-weight: 800;
//     font-display: swap;
//     src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggexSvfedN4.woff2) format('woff2');
//     unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
//   }
//   /* greek */
//   @font-face {
//     font-family: 'Manrope';
//     font-style: normal;
//     font-weight: 800;
//     font-display: swap;
//     src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggSxSvfedN62Zw.woff2) format('woff2');
//     unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
//   }
//   /* vietnamese */
//   @font-face {
//     font-family: 'Manrope';
//     font-style: normal;
//     font-weight: 800;
//     font-display: swap;
//     src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggixSvfedN62Zw.woff2) format('woff2');
//     unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
//   }
//   /* latin-ext */
//   @font-face {
//     font-family: 'Manrope';
//     font-style: normal;
//     font-weight: 800;
//     font-display: swap;
//     src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggmxSvfedN62Zw.woff2) format('woff2');
//     unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
//   }
/* latin */
@font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(./fonts/Manrope/xn7gYHE41ni1AdIRggexSvfedN4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

$font-inter: 'Inter', sans-serif;
$font-manrope: 'Manrope', sans-serif;

$primary-color: #DD0744;
$text-color-heading: #1D1E25;
$text-color-paragraph: #717184;

// media breakpoints
$gt-small-screen: 480px;
$gt-medium-screen: 768px;
$gt-large-screen: 1024px;
$gt-extra-large-screen: 1200px;
