// index.scss

@import '../../../styles/variables.scss';
// Custom styles for the header
.account-card{
  border-radius: 15px;
  // margin-bottom: 20px;
  background-color: #fff;
  
  .cardcontainer{
    .header{
      display: flex;
      justify-content: space-between;
      padding: 20px;
      padding-bottom: 0px;
      margin-bottom: 0px;
      h6{
        color: #1D1E25;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .button-group{
        display: flex;
        gap: 10px;
      }
    }
    .ant-divider{
      margin: 18px 0px;
    }
    .formcontainer{
      padding: 20px;
      padding-top: 0px;
      .input-container{
        margin-bottom: 10px;
        label{
          display: inline-block;
          color: #1D1E25;
          font-feature-settings: 'clig' off, 'liga' off;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 10px;
        }
        .input-text{
          height: 45px!important;
        }
        .error-message{
          color: red;
        }
      }
    }
   
  }



}