.verification-modal{
    width: 80vw;
}

/* Adjust width for desktop view */
@media (min-width: 768px) {
    .verification-modal {
        width: 50vw !important;
    }
}

.ant-select .ant-select-selector{
    background: #F6F6F6 !important;
}

.ant-select:hover .ant-select-selector{
    border-color: transparent !important;
} 

.ant-modal-content .modal-ok-button{
    background-image: linear-gradient(to right, #DD0744 0%, #FF9A44 130%);
}  

.ant-modal-content .modal-ok-button:hover{
    background-image: linear-gradient(to right, #FF9A44 0%, #DD0744 130%) !important;
} 

/* .custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    box-sizing: border-box  ;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    z-index: 99;
} */
  
/* .custom-modal {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    width: 400px;
    margin: 50px auto;
    overflow: auto;
} */
  
/* .custom-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
} */
  /* .close-button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #aaa;
  } */
  
/* .custom-modal-content {
    margin-top: 10px;
} */