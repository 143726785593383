@import '../../../styles/variables.scss';


.pageHeader{
    h1{
        color: $text-color-heading;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        span{
            color: $primary-color;
        }
    }
    p{
        color: $text-color-paragraph;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}