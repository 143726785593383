// index.scss

@import '../../../styles/variables.scss';
// Custom styles for the header
.competittion-card{
    background: #fff;
    padding:20px ;
    border-radius: 15px;
    margin-bottom:30px ;
    .header{
        gap: 20px;
        display: flex;
        justify-content: start!important;


        .iconbox{
            width: 60px;
            min-width: 60px;
            height: 60px;
            border-radius: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(91deg, #DD0744 0.7%, #FF9A44 143.6%);

            &.finished{
                background: #f6f6f6;
            }
        }

        .jobdetail{
            display: block;

            h1{
                color: $text-color-heading;
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: Inter;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            .statusBadge{
                width: fit-content;
                margin-bottom:10px ;
            }
        }
    }

    .competitiation-detail{
        background-color: #F6F6F6;
        padding: 12px 20px;
        border-radius: 10px;
        margin-bottom: 18px;

        .heading{
            color: $text-color-paragraph;
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
            display: flex;
            span{
                margin-right: 10px;
            }
        }
        .detail{
            color: $text-color-heading;
            // color: #1D1E25;
            font-size: 11px;
            font-style: normal;
            font-weight: 700;
            line-height: 17px;
        }
    }

    .time-gmt{
        color: #1D1E25;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        margin-bottom: 10px;
    }

    .finishedTime{
        margin-top: 6px;
        margin-bottom: 53px;
        color: #1D1E25;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .competition-card-btn:hover{
        .svg-container {
            .svg-path {
              fill: #fFF; // Change to your desired hover color
            }
        }
    }
}