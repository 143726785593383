// index.scss

@import '../../../styles/variables.scss';
// Custom styles for the header
.challengeCard{
  padding: 20px;
  border-radius: 15px;
  background-color: #fff;
  margin-bottom: 20px;
  .cardcontainer{
    display: flex;
    justify-content: space-between;
    .intertnal{
      display: flex;
      gap: 1rem;
      .imagesSection{
        width: 80px;
        height: 80px;
        border-radius: 12px;
        background: rgba(222, 12, 68, 0.10);
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          object-fit: contain;
  
        }
      }
      .pkgdetail{
  
        .packageInfo{
          display: flex;
          flex-direction: column;
          gap: 15px;
          margin-bottom: 14px;
          h1{
            color: #1D1E25;
            font-feature-settings: 'clig' off, 'liga' off;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
          p{
            color: #717184;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
     
      }
    }
    .linkArrow{
      display: flex;
      align-items: center;
      justify-content: 'center';
      color: #1D1E25;
      text-align: right;
      font-family: Inter;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      span{
        margin-left: 10px;
      }
  
    }
  
  }



}